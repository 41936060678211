import request from '@/api/request.js';

// 促销活动列表
export function list(data) {
    return request({
        url: 'business/api/marketing/list',
        method: 'post',
        data: data
    });
}
// 优惠券列表
export function couponList(data) {
    return request({
        url: 'business/api/marketing/couponList',
        method: 'post',
        data: data
    });
}

// 添加/编辑优惠券
export function couponAction(data) {
    return request({
        url: 'business/api/marketing/couponAction',
        method: 'post',
        data: data
    });
}


// 优惠券详情
export function couponDetails(data) {
    return request({
        url: 'business/api/marketing/couponDetails',
        method: 'get',
        params: data
    });
}

// 优惠券基本信息
export function getCouponInfo(data) {
    return request({
        url: 'business/api/marketing/getCouponInfo',
        method: 'get',
        params: data
    });
}
// 优惠券订单列表
export function couponOrderList(data) {
    return request({
        url: 'business/api/marketing/couponOrderList',
        method: 'post',
        data: data
    });
}
// 结束/删除优惠券
export function deleteCoupon(data) {
    return request({
        url: 'business/api/marketing/deleteCoupon',
        method: 'delete',
        data: data
    });
}
// 折扣列表
export function discountList(data) {
    return request({
        url: 'business/api/marketing/discountList',
        method: 'post',
        data: data
    });
}
// 添加/编辑折扣
export function discountAction(data) {
    return request({
        url: 'business/api/marketing/discountAction',
        method: 'post',
        data: data
    });
}

// 折扣基本信息
export function getDiscountInfo(data) {
    return request({
        url: 'business/api/marketing/getDiscountInfo',
        method: 'get',
        params: data
    });
}
// 折扣详情
export function discountDetails(data) {
    return request({
        url: 'business/api/marketing/discountDetails',
        method: 'post',
        data: data
    });
}
// 折扣编辑数据
export function discountFind(data) {
    return request({
        url: 'business/api/marketing/find',
        method: 'get',
        params: data
    });
}

// 折扣订单列表
export function discountOrderList(data) {
    return request({
        url: 'business/api/marketing/discountOrderList',
        method: 'post',
        data: data
    });
}

// 结束/删除折扣
export function deleteDiscount(data) {
    return request({
        url: 'business/api/marketing/deleteDiscount',
        method: 'delete',
        data: data
    });
}
// 套装优惠列表
export function suitList(data) {
    return request({
        url: 'business/api/marketing/suitList',
        method: 'post',
        data: data
    });
}
// 添加/编辑套装优惠
export function suitAction(data) {
    return request({
        url: 'business/api/marketing/suitAction',
        method: 'post',
        data: data
    });
}
// 套装详情列表
export function suitDetails(data) {
    return request({
        url: 'business/api/marketing/suitDetails',
        method: 'post',
        data: data
    });
}
// 获取套装基本信息
export function getSuitInfo(data) {
    return request({
        url: 'business/api/marketing/getSuitInfo',
        method: 'get',
        params: data
    });
}

// 套装编辑数据
export function findSuit(data) {
    return request({
        url: 'business/api/marketing/findSuit',
        method: 'get',
        params: data
    });
}

// 套装订单列表
export function suitOrderList(data) {
    return request({
        url: 'business/api/marketing/suitOrderList',
        method: 'post',
        data: data
    });
}
// 结束/删除套装
export function deleteSuit(data) {
    return request({
        url: 'business/api/marketing/deleteSuit',
        method: 'delete',
        data: data
    });
}

// 获取商品标准
export function getGoodsStandard(data) {
    return request({
        url: 'business/api/marketing/getGoodsStandard',
        method: 'get',
        params: data
    });
}

// 添加/编辑限时选购
export function limitPurchaseAction(data) {
    return request({
        url: 'business/api/marketing/limitPurchaseAction',
        method: 'post',
        data: data
    });
}

// 限时选购列表
export function limitPurchaseList(data) {
    return request({
        url: 'business/api/marketing/limitPurchaseList',
        method: 'post',
        data: data
    });
}
// 开启/关闭限时选购
export function changeStatus(data) {
    return request({
        url: 'business/api/marketing/changeStatus',
        method: 'post',
        data: data
    });
}

// 获取限时抢购详情
export function limitPurchaseDetails(data) {
    return request({
        url: 'business/api/marketing/limitPurchaseDetails',
        method: 'post',
        data: data
    });
}
// 结束/删除限时选购
export function deleteLimitPurchases(data) {
    return request({
        url: 'business/api/marketing/deleteLimitPurchases',
        method: 'delete',
        data: data
    });
}
// 获取限时选购基本信息
export function getLimitPurchaseInfo(data) {
    return request({
        url: 'business/api/marketing/getLimitPurchaseInfo',
        method: 'get',
        params: data
    });
}
// 获取限时选购基本信息
export function limitPurchaseOrderList(data) {
    return request({
        url: 'business/api/marketing/limitPurchaseOrderList',
        method: 'post',
        data: data
    });
}

// shopee广告管理 - 1.2-获取广告列表

export function getAdvertisementList(data) {
    return request({
        url: 'business/api/advert/getAdvertisementList',
        method: 'post',
        data: data
    });
}

// shopee广告管理 - 1.2-添加(编辑)广告

export function operateAdvertisement(data) {
    return request({
        url: 'business/api/advert/operateAdvertisement',
        method: 'post',
        data: data
    });
}

// shopee广告管理 - 1.6-获取广告充值金额

export function getRechargeAmount(data) {
    return request({
        url: 'business/api/advert/getRechargeAmount',
        method: 'get',
        params: data
    });
}
// shopee广告管理 - 1.7-充值

export function recharge(data) {
    return request({
        url: 'business/api/advert/recharge',
        method: 'post',
        data: data
    });
}
// shopee广告管理 - 1.3-获取商品关键词
export function getGoodsKeyword(data) {
    return request({
        url: 'business/api/advert/getGoodsKeyword',
        method: 'post',
        data: data
    });
}

// shopee广告管理 - 1.4-获取广告信息
export function getAdvertisementInfo(data) {
    return request({
        url: 'business/api/advert/getAdvertisementInfo',
        method: 'get',
        params: data
    });
}

// shopee广告管理 - 1.5-更改状态
export function upAdvertisementState(data) {
    return request({
        url: 'business/api/advert/upAdvertisementState',
        method: 'post',
        data: data
    });
}
<template>
  <div class="addAdvertising">
    <div class="top-msg" v-if="advert_money < 1">
      <el-alert type="warning" show-icon :closable="false">
        <div slot="title">
          您的广告预算不足，请充值以确保您的促销不会被中断<span @click="toPush()"
            style="margin-left: 10px; color: #2d77dd; cursor: pointer">立即充值</span>
        </div>
      </el-alert>
    </div>
    <div style="font-size: 20px; padding: 20px 0">创建搜索广告</div>
    <div class="card" style="position: relative;">
      <div class="card-title">基础设置</div>
      <div style="padding-left: 120px">
        <div class="card-item" style="align-items: center">
          <div class="card-label">推广类型</div>
          <div style="display: flex;">
            <div @click="handleClick(item.key)" v-for="item in typeOp" :key="item.key" style="
                background: #fff;
                padding: 10px;
                width: 170px;
                height: 70px;
                box-shadow: 0 0 16px 1px #eee;
                border-radius: 4px;
                display: flex;
                align-items: center;
                position: relative;
                margin-right: 20px;
                cursor: pointer;
              ">
              <el-image style="width: 50px; height: 50px" :src="goodsImg" fit="fit"></el-image>
              <span style="font-size: 14px; margin-left: 10px">{{item.name}}</span>
              <div style="
                  position: absolute;
                  right: 0;
                  top: 0;
             
                  border-bottom: 14px solid transparent;
                  border-left: 14px solid transparent;
                "
                :style="form.promotion_type==item.key?'border-top: 14px solid #ee4d2d;border-right: 14px solid #ee4d2d;':'     border-top: 14px solid #7D7C7E;border-right: 14px solid #7D7C7E;'">
                <i class="el-icon-check" style="color: #fff; font-size: 10px"></i>
              </div>
            </div>

          </div>
        </div>

        <div v-if="form.promotion_type==0" class="card-item" style="margin-top: 30px;align-items: center;">
          <div class="card-label">广告名称</div>
          <div>
            <el-input size="small" style="width:450px" :maxlength="50" :show-word-limit="true" v-model="form.name"
              placeholder="请输入广告名称"></el-input>
          </div>
        </div>
        <div class="card-item" style="margin-top: 30px">
          <div class="card-label">预算</div>
          <div>
            <el-radio v-model="form.budget_limit" :label="0" style="margin-bottom: 10px"
              @input="handleInput">无限制</el-radio>
            <div style="
                font-size: 12px;
                color: #999;
                margin-top: 4px;
                margin-bottom: -10px;
                margin-left: 25px;
              " v-if="form.budget_limit == 0">
              根据您目前的广告预算余额，您的广告最多可获得<span style="color: #ee4d2d">0</span>个点击数。
            </div>
            <br />
            <el-radio v-model="form.budget_limit" :label="1">设定预算</el-radio>
            <div v-if="form.budget_limit == 1">
              <el-input size="small" style="width: 180px; margin-left: 25px; margin-top: 10px" placeholder="请输入内容"
                v-model="form.budget">
                <template slot="prepend">RM</template>
              </el-input>
              <el-select style="width: 140px" size="small " v-model="form.budget_type" placeholder="请选择">
                <el-option label="每日预算" :value="0"> </el-option>
                <el-option label="总预算" :value="1"> </el-option>
              </el-select>
            </div>
            <div v-if="form.budget_limit == 1" style="
                font-size: 12px;
                color: #999;
                margin-top: 4px;
                margin-bottom: -10px;
                margin-left: 25px;
              ">
              根据您目前的预算，此促销活动可支持最多<span style="color: #ee4d2d">7</span>次点击数。（每日）
            </div>
          </div>
        </div>
        <div class="card-item" style="margin-top: 30px">
          <div class="card-label">时间长度</div>
          <div>
            <el-radio v-model="form.length_time" :label="0" style="margin-bottom: 10px">不限时</el-radio>

            <br />
            <el-radio v-model="form.length_time" :label="1">设定开始日期/结束日期</el-radio>
            <div v-if="form.length_time == 1" style="padding-left: 25px; padding-top: 10px">
              <el-date-picker size="small" @change="handelTimeChange" v-model="form.time" type="daterange"
                value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form.promotion_type==1">
        <div class="card-title">商品设置</div>
        <div style="padding-left: 120px">
          <div class="card-item">
            <div class="card-label">商品</div>
            <div>
              <div style="font-size: 14px">
                <span style="color: #ee4d2d">{{ goodsList.length - 1 }}</span> /
                50已选商品
              </div>
              <div style="font-size: 12px; color: #999; margin-top: 4px">
                一旦您选择多过1个商品，Shopee将自动管理广告关键字，相同的预算和时长设置将应用于每个广告。
              </div>
              <div style="margin-top: 10px; display: flex; flex-wrap: wrap">
                <div v-for="(item, i) in goodsList" :key="i" style="margin-right: 10px; margin-bottom: 10px">
                  <div class="goods-sel" @click="openGoods" v-if="!goodsList[i + 1] && i < 50">
                    <i style="font-size: 20px" class="el-icon-plus"></i>
                  </div>

                  <div class="goods-img" v-if="goodsList[i + 1]">
                    <div class="goods-img_float">
                      <i class="el-icon-delete" @click="handleGoodsDel(i)"></i>
                    </div>
                    <el-image style="width: 80px; height: 80px" :src="item.url" fit="fit"></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card-title">广告创意</div>
        <div style="padding-left: 120px">
          <div class="card-item" style="margin-top: 30px">
            <div class="card-label" style="padding-top:6px;">落地页</div>
            <div>
              <el-input disabled size="small" style="width:450px;" :maxlength="50" :show-word-limit="true"
                v-model="form.jump_url" placeholder="主商店页面（预设）"></el-input>
              <div style="font-size: 13px;margin-top: 8px;color:#666">
                请选择买家在点击您的商店广告后将会前往的页面
              </div>
            </div>
          </div>
          <div class="card-item" style="margin-top: 30px">
            <div class="card-label" style="padding-top:6px;">商店搜索广告图片</div>
            <div style="display: flex;align-items: center;">
              <div style="width:90px;height:90px;position: relative;border-radius: 50%;overflow: hidden;">
                <el-image style="width: 90px; height: 90px;border-radius: 50%;" :src="url">
                  <div slot="error" style="background: #c0c4cc;width: 100%;height: 100%;">

                  </div>
                </el-image>
                <el-upload v-if="upshow" :show-file-list="false" style="position: absolute;bottom: 0;"
                  class="upload-demo" :action="action" :headers="headers" name="imagelist"
                  :on-success="handleAvatarSuccess" multiple :limit="1" :file-list="fileList">
                  <div style="background: #999;color: #fff;font-size: 14px;padding: 4px 0;width: 90px;">
                    编辑</div>
                </el-upload>
              </div>

              <div style="font-size: 13px;margin-left: 20px;color: #666;">
                选择您在商店广告上显示的商店图片。建议：使用一张最<br />热销的商品图片，以便买家在您的登录页面上查看。
              </div>

            </div>
          </div>
          <div class="card-item" style="margin-top: 30px">
            <div class="card-label" style="padding-top:6px;">标语</div>
            <div>
              <el-input size="small" style="width:450px" :maxlength="25" :show-word-limit="true" v-model="form.slogan"
                placeholder="请输入标语"></el-input>
              <div style="font-size: 13px;margin-top: 8px;color:#666">
                选择在您商店广告中显示的商店描述。这将会预设为您的商店名称。建议：使<br />用简短的销售讯息以吸买家到您的商店看看。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-title">关键词设置</div>
      <div style="padding-left: 120px">
        <div class="card-item" v-if="form.promotion_type==1">
          <div class="card-label" style="padding-top: 4px">自动选择</div>
          <div>
            <el-switch v-model="form.keyword_set" active-color="#13ce66" inactive-color="#B0B0B0" :active-value="0"
              :inactive-value="1" :disabled="goodsList.length > 2">
            </el-switch>
            <div style="font-size: 12px; color: #999; margin-top: 6px">
              不确定该选择什么关键词？开启自动选择，以便Shopee系统自动为您管理。
            </div>
          </div>
        </div>
        <div class="card-item" v-if="form.keyword_set == 1">
          <div class="card-label">手动选择</div>
          <div style="width: 100%">
            <el-button @click="openKey" style="color: #ee4d2d; border-color: #ee4d2d" size="medium"
              icon="el-icon-plus">增加推荐的关键词</el-button>
            <div style="border: 1px solid #eee; margin-top: 10px" v-if="addKeyList.length != 0">
              <el-table :header-cell-style="{
                background: '#F6F6F6',
              }" :data="addKeyList" style="width: 100%">
                <el-table-column prop="keyword" label="关键字" width="180">
                </el-table-column>
                <el-table-column prop="quality_score" label="品质分数" width="180">
                  <template slot-scope="scope">
                    <div>
                      <el-progress :stroke-width="8" :format="format"
                        :percentage="scope.row.quality_score"></el-progress>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="search_volume" label="搜索量">
                </el-table-column>
                <el-table-column prop="match_type" label="匹配类型">
                  <template slot-scope="scope">
                    <div v-if="scope">
                      <el-select size="small" v-model="scope.row.match_type" placeholder="请选择">
                        <el-option label="广泛匹配" value="广泛匹配">
                        </el-option>
                        <el-option label="精准匹配" value="精准匹配">
                        </el-option>
                      </el-select>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="recommended_bid" label="推荐出价">
                  <template slot-scope="scope">
                    <div>RM {{ scope.row.recommended_bid }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="click_bid" label="点击出价">
                  <template slot-scope="scope">
                    <div>
                      <el-input size="small" placeholder="请输入内容" v-model="scope.row.click_bid">
                        <template slot="prepend">RM</template>
                      </el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="80" align="center" prop="click_bid" label="">
                  <template slot-scope="scope">
                    <div v-if="scope">
                      <i @click="handleDel(scope.$index, scope.row.id)" style="font-size: 20px; cursor: pointer"
                        class="el-icon-delete"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

      </div>


      <div v-if="form.promotion_type==0"
        style="position: absolute;right: 80px;top: 430px;border-radius: 4px;border: 1px solid #b0b0b0;width:520px;padding: 20px 20px 40px;">
        <div style="font-weight: bold;font-size: 20px;color: #333;">广告预览</div>
        <div style="margin-top: 20px;">
          <div
            style="border-radius: 2px;border: 1px solid #eee;display: flex;align-items: center;justify-content: space-between;">
            <div style="display: flex;align-items: center;">
              <div style="background: #FAFAFA;padding: 10px;text-align: center;line-height: 44px;">App</div>
              <div style="margin: 0 10px;">
                <el-avatar :size="40" :src="url"></el-avatar>
              </div>
              <div style="font-weight: bold;max-width: 250px;">
                {{form.slogan}}
              </div>
            </div>
            <div style="border-left: 1px solid #eee;padding-right: 10px;color: #ee4d2d;">
              更多商店<i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <div
            style="border-radius: 2px;border: 1px solid #eee;display: flex;align-items: center;justify-content: space-between;">
            <div style="display: flex;align-items: center;">
              <div style="background: #FAFAFA;padding: 10px;text-align: center;line-height: 34px;">PC</div>
              <div style="margin: 0 10px;">
                <el-avatar :size="30" :src="url"></el-avatar>
              </div>
              <div style="font-weight: bold;font-size: 12px;max-width: 250px;">
                {{form.slogan}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: right; margin: 30px 0 10px">
        <el-button @click="toBack">取消发布</el-button>
        <el-button type="primary" @click="handleAdd">确认发布</el-button>
      </div>
    </div>
    <MarketingGoods :type="1" ref="goods" :show="dialogVisible"></MarketingGoods>

    <!-- 关键词弹窗 -->
    <el-dialog @close="handleCL" :close-on-click-modal="false" title="新建关键词" :visible.sync="dialogVisible1"
      width="1200px" :before-close="handleClose" style="margin-top: -80px">
      <div style="display: flex">
        <div style="width: 64%; margin-right: 20px">
          <el-input size="medium" placeholder="在这里输入您想要新增的关键字" v-model="input" class="input-with-select">
            <el-button @click="handleSearch" slot="append" type="primary">添加</el-button>
          </el-input>
          <div style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 20px;
            ">
            <span style="display: flex; align-items: center">
              <svg t="1669255025671" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="8469" width="20" height="20">
                <path
                  d="M848.03584 438.87104a95.52896 95.52896 0 0 0-75.24352-36.352H604.8768c9.16992-25.56928 15.23712-53.35552 17.39264-80.24064 3.35872-41.80992-3.072-78.94528-18.5856-107.392-19.09248-34.9952-51.59424-56.82176-94.0032-63.12448-19.21024-2.85696-35.73248 0.83968-49.11104 10.97216-27.04384 20.48512-32.00512 59.47904-37.76 104.62208-4.59264 36.09088-9.8048 76.99456-27.30496 106.3168-11.68384 19.56864-34.41664 45.58336-96.74752 45.58336H233.71264c-43.3152 0-78.55616 35.2256-78.55616 78.52544v296.87808c0 43.29472 35.24096 78.52544 78.55616 78.52544h473.87648c21.5808 0 42.76736-7.37792 59.66336-20.78208s28.91264-32.34304 33.82784-53.34528l65.19808-278.71232a95.4112 95.4112 0 0 0-18.24256-81.47456zM207.5136 794.65472V497.77664a26.2144 26.2144 0 0 1 26.19392-26.16832h55.1936v349.2096h-55.1936a26.2144 26.2144 0 0 1-26.19392-26.1632z m607.78496-286.2336l-65.2032 278.71744a43.45856 43.45856 0 0 1-42.50624 33.68448H341.26336V467.8144c44.2368-8.44288 77.51168-30.96576 99.2-67.30752 23.10656-38.70208 29.04576-85.36576 34.28864-126.53568 2.36544-18.60608 4.60288-36.1728 8.15616-49.65376 3.8144-14.45888 7.7568-18.69312 9.27744-19.84512 1.73568-1.31584 5.02784-1.62816 9.79968-0.91648 26.26048 3.90144 44.4928 15.81056 55.72608 36.40832 23.05024 42.25024 12.86656 113.56672-9.09824 162.17088a37.23264 37.23264 0 0 0 2.62656 35.68128 37.1968 37.1968 0 0 0 31.39072 17.07008h190.16192a43.43296 43.43296 0 0 1 34.21696 16.53248 43.32032 43.32032 0 0 1 8.28928 37.00224z"
                  fill="#FF962F" p-id="8470"></path>
              </svg>
              <span style="color: #999; font-size: 14px">Shopee推荐</span>
            </span>
            <span>
              <el-button size="small" type="primary" @click="addKey('all')">新增全部 <i class="el-icon-d-arrow-right"></i>
              </el-button></span>
          </div>
          <div style="border: 1px solid #eee; margin-top: 10px">
            <el-table :key="refNum" size="small " :header-cell-style="{
              background: '#F6F6F6',
            }" :data="tableData" style="width: 100%" max-height="450px">
              <el-table-column prop="keyword" label="关键字" width="180">
              </el-table-column>
              <el-table-column prop="quality_score" label="品质分数" width="180">
                <template slot-scope="scope">
                  <el-progress :text-inside="true" :stroke-width="10" :show-text="false"
                    :percentage="scope.row.quality_score"></el-progress>
                </template>
              </el-table-column>
              <el-table-column prop="search_volume" label="搜索量">
              </el-table-column>
              <el-table-column prop="recommended_bid" label="推荐出价">
              </el-table-column>
              <el-table-column prop="address" label="操作">
                <template slot-scope="scope">
                  <el-button @click="addKey(scope.$index, scope.row)" type="text" style="color: #2d77de"
                    v-if="!scope.row.xz">新增
                    <i class="el-icon-arrow-right"></i>
                  </el-button>
                  <el-button disabled type="text" v-else>已新增 </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div style="width: 35%; padding-top: 64px">
          <div style="margin-bottom: 20px">
            已新建关键字({{ addKeyList.length }})
          </div>
          <div style="
              padding: 10px 0;
              border: 1px solid #eee;
              height: 450px;
              overflow: scroll;
            ">
            <div>
              <div id="disc-cell" v-for="(item, i) in addKeyList" :key="i" style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 10px 10px 10px 20px;
                ">
                <div style="display: flex; align-items: center">
                  <span style="color: #999; margin-right: 10px">{{
                    i + 1
                    }}</span>
                  <span style="display: inline-block; width: 160px" class="single-hidden">
                    {{ item.keyword }}
                  </span>
                </div>
                <div style="display: flex; align-items: center">
                  <el-input size="small" style="width: 140px" placeholder="请输入" v-model="item.click_bid">
                    <template slot="prepend">RM</template>
                  </el-input>
                  <i @click="handleDel(i, item.id)" style="
                      font-size: 18px;
                      color: #999;
                      margin-left: 10px;
                      cursor: pointer;
                    " class="el-icon-error"></i>
                </div>
              </div>
            </div>
          </div>
          <div style="
              border: 1px solid #eee;
              padding: 20px;
              border-top: 1px solid transparent;
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <span style="color: #999">批量设定匹配模式</span>
            <el-popover v-model="matcShow" placement="top-end" width="360" trigger="click">
              <div style="font-size: 12px; color: #999">
                <div @click="handleXzPp(1)" id="disc-cell" style="padding: 5px; margin: -5px; cursor: pointer">
                  <div style="font-size: 16px; color: #333; margin-bottom: 2px"
                    :style="{ color: matcIndex == 1 ? '#ee4d2d' : '#000' }">
                    广泛匹配
                  </div>
                  <div>
                    系统高度推荐使用模式。只要搜寻包含此关键词，或与之有关的内容，您投放的广告就有机会出现。这有助于您的广告覆盖更广泛的受众群体，为产品带来最大限度的曝光。推荐所有卖家优先选择这个模式。
                  </div>
                </div>
                <div @click="handleXzPp(2)" id="disc-cell" style="padding: 5px; margin: -5px; cursor: pointer">
                  <div style="font-size: 16px; color: #333; margin: 10px 0 2px"
                    :style="{ color: matcIndex == 2 ? '#ee4d2d' : '#000' }">
                    精准匹配
                  </div>
                  <div>
                    不推荐模式！只有搜寻关键词时，您投放的广告才会出现。此形式最大限度限制了关键词的曝光，请根据实际需求谨慎选择使用。
                  </div>
                </div>
              </div>
              <div slot="reference" style="
                  border: 0.1px solid #b4bccc;
                  width: 180px;
                  height: 36px;
                  display: flex;
                  align-items: center;
                  border-radius: 5px;
                  cursor: pointer;
                  justify-content: space-around;
                ">
                <span> {{ matcIndex == 1 ? "广泛匹配" : "精准匹配" }}</span>
                <i class="el-icon-arrow-down"></i>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <div style="
          display: flex;
          justify-content: right;
          padding: 20px 20px 20px 0;
          margin: 0 -24px -24px -24px;
          box-shadow: 0 -8px 20px -15px rgba(0, 0, 0, 0.4);
        ">
        <el-button @click="dialogVisible1 = false">取消</el-button>
        <el-button type="primary" @click="handleQdList">确认新增关键词</el-button>
      </div>
    </el-dialog>
    <!-- 关键词弹窗 -->
  </div>
</template>
<script>
  import { getSAuth, getSchool } from "@/utils/auth.js";
  import { deleteFile } from "@/api/common.js";
  import env from "@/settings/env.js";
  import MarketingGoods from "@/components/goods/MarketingGoods.vue";
  import { operateAdvertisement, getGoodsKeyword } from "@/api/marketing.js";
  export default {
    components: { MarketingGoods },
    data() {
      return {
        action: env.apiUrlPrefix + "api/upload/files",
        typeOp: [{ key: 1, name: '商品' }, { key: 0, name: '商店' }],
        fileList: [],
        form: {
          promotion_type: 0,
          name: '',
          budget_limit: 0,
          budget: "",
          budget_type: 0,
          length_time: 0,
          start_time: "",
          end_time: "",
          time: [],
          goods_ids: "",
          keyword_set: 1,
          keyword_list: [],
          jump_url: '',
          file_id: '',
          slogan: '',

        },
        advert_money: 0,
        input: "",
        goodsImg: require("@/assets/img/marketing/goods.jpg"),
        goodsList: [
          {
            url: "",
            id: 0,
          },
        ],
        dialogVisible: false,
        dialogVisible1: false,
        tableData: [],
        addKeyList: [],
        refNum: 1,
        matcIndex: 1,
        matcShow: false,
        keyList: [],
        sdxzID: 0,
        virtList: [],
        headers: {
          Authorization: getSAuth(),
          school: getSchool(),
        },
        url: '',
        upshow: true
      };
    },
    created() {
      if (sessionStorage.ADVERT_MONEY) {
        this.advert_money = sessionStorage.ADVERT_MONEY;
      }
    },
    methods: {
      openGoods() {
        this.dialogVisible = true;
      },
      // 确认选择好的商品
      handleAndGoods(arr) {
        if (arr.length > 50) {
          this.$message.warning("商品个数不能大于50");
          return false;
        }
        let list = [];
        arr.forEach((item) => {
          list.push({
            url: item.image,
            id: item.id,
            price: item.price
          });
        });
        list.push({
          url: "",
          id: 0,
        });
        this.goodsList = list;
        if (this.goodsList.length > 2) {
          this.form.keyword_set = 0;
        }
        this.dialogVisible = false;
      },
      // 删除商品
      handleGoodsDel(i) {
        this.goodsList.splice(i, 1);
        this.sdxzID = "";
        this.addKeyList = [];
        this.tableData = [];
        this.virtList = [];
        this.$refs.goods.$data.checkedCities.splice(i, 1);
      },
      handleClose() {
        this.dialogVisible1 = false;
      },
      // 确认新增
      handleAdd() {

        if (this.form.promotion_type == 1) {
          let arr = [];
          this.goodsList.forEach((item) => {
            if (item.id != 0) {
              arr.push(item.id);
            }
          });
          if (arr.length == 0) {
            this.$message.warning("请至少选择一个商品");
            return false;
          }
          this.form.goods_ids = arr.join(",");
        } else {
          if (this.form.name == '') {
            this.$message.error('请输入广告名称')
            return false
          }
          if (this.form.file_id == '') {
            this.$message.error('请上传广告图片')
            return false
          }
          if (this.form.slogan == '') {
            this.$message.error('请输入广告标语')
            return false
          }
        }

        if (this.form.keyword_set == 1) {
          this.addKeyList.forEach((item) => {
            item.name = item.keyword;
          });
          this.form.keyword_list = this.addKeyList;
        } else {
          this.addKeyList = [];
          this.form.keyword_list = [];
        }
        operateAdvertisement({
          ...this.form,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("发布成功");
            this.toBack();
          }
        });
      },
      handleInput() {
        this.form.budget = "";
        this.form.budget_type = 0;
      },
      // 打开关键词弹窗
      openKey() {
        if (this.form.promotion_type == 1) {
          if (this.goodsList.length - 1 == 0) {
            this.$message.warning("请最少选择一个商品");
            return false;
          }
        }

        if (this.sdxzID == this.goodsList[0].id) {
          this.dialogVisible1 = true;
          return false;
        }
        getGoodsKeyword({
          goods_id: this.goodsList[0].id,
        }).then((res) => {
          if (res.code == 1) {
            this.sdxzID = this.goodsList[0].id;
            this.tableData = res.data.statics;
            // 获取列表后，新增属性
            this.tableData.forEach((item, i) => {
              item.id = i + 1;
              item.xz = false;
              item.click_bid = item.recommended_bid;
              item.match_type = "";
            });
            this.virtList = this.tableData;
            this.dialogVisible1 = true;
          }
        });
      },
      toBack() {
        this.$router.go(-1);
      },
      // 时间选择
      handelTimeChange(time) {
        this.form.start_time = parseInt(time[0] / 1000);
        this.form.end_time = parseInt(time[1] / 1000);
      },
      // 新增关键字
      addKey(index, val) {
        if (index === "all") {
          let arr = [];
          this.addKeyList.forEach((item) => {
            arr.push(item.id);
          });
          this.tableData.forEach((item, i) => {
            if (arr.indexOf(item.id) == -1) {
              item.xz = true;
              this.$set(this.tableData, i, item);
              let data = JSON.stringify(item);
              this.addKeyList.push(JSON.parse(data));
            }
          });
        } else {
          val.xz = true;
          this.$set(this.tableData, index, val);
          let data = JSON.stringify(val);
          this.addKeyList.push(JSON.parse(data));
        }
      },
      // 删除关键字
      handleDel(index, id) {
        this.tableData.forEach((item, i) => {
          if (item.id == id) {
            item.xz = false;
            this.$set(this.tableData, i, item);
          }
        });
        this.virtList.forEach((item, i) => {
          if (item.id == id) {
            item.xz = false;
            this.$set(this.virtList, i, item);
          }
        });
        this.addKeyList.splice(index, 1);
      },
      handleXzPp(val) {
        this.matcIndex = val;
        this.matcShow = false;
      },
      // 确定关键词列表
      handleQdList() {
        let text = this.matcIndex == 1 ? "广泛匹配" : "精准匹配";
        // 批量设置匹配模式
        this.addKeyList.forEach((item) => {
          item.match_type = text;
        });
        this.dialogVisible1 = false;
      },
      format() {
        return " ";
      },
      // 关键词列表搜索
      handleSearch() {
        // let arr = [];
        // this.virtList.forEach((item) => {
        //   if (item.keyword.indexOf(this.input) != -1) {
        //     arr.push(item);
        //   }
        // });

        // this.tableData = arr;

        let price = this.form.promotion_type == 1 ? this.goodsList[0].price : 10
        // 添加

        // 品质分数=随机在5~100取值(分值高的排在前面,超过50%+热门标签)
        let q = this.randomNum(5, 100)
        // 搜索量=品质分数*商品价格，保留整数部分
        let s = (price * q).toFixed(0)
        // 推荐出价=商品价格*30%*品质分数
        let r = (price * 0.3 * q / 100).toFixed(2)



        this.virtList.unshift({
          keyword: this.input,
          match_type: '',
          is_hot: q > 50 ? 1 : 0,
          quality_score: q,
          search_volume: s,
          recommended_bid: r,
          click_bid: r,
          xz: false
        })
        this.tableData = this.virtList
      },
      toPush() {
        this.$router.push("topUp");
      },

      randomNum(num1, num2) {
        let max = Math.max(num1, num2);
        let min = Math.min(num1, num2);
        return Math.floor(Math.random() * (max - min + 1)) + min
      },

      handleCL() {
        this.input = ''
      },

      handleClick(index) {
        this.form.promotion_type = index
        this.tableData = [];
        this.virtList = [];
        this.addKeyList = [];
        this.goodsList = [
          {
            url: "",
            id: 0,
          },
        ]
        this.form.keyword_set=1
      },

      handleAvatarSuccess(res) {
        if (res.code == 1) {
          this.$message.success("上传成功");
          this.url = res.data.url;
          this.form.file_id = res.data.id;
        } else {
          this.$message.error(res.message);
        }

        this.upshow = false
        let _this = this
        setTimeout(function () {
          _this.upshow = true
        }, 100)
      }
    },
  };
</script>
<style lang="less">
  .addAdvertising {
    width: 1450px;
    margin: 0 auto;
    padding: 20px 0 40px;

    .top-msg {
      padding: 20px;
      background: #fff;
      border-radius: 4px;
    }

    .card {
      padding: 20px;
      background: #fff;
      border-radius: 4px;

      .card-title {
        font-size: 18px;
      }

      .card-item {
        display: flex;
        margin-bottom: 20px;

        .card-label {
          min-width: 112px;
          margin-right: 20px;
          text-align: right;
          font-size: 14px;
        }

        .goods-sel {
          width: 80px;
          height: 80px;
          border: 1px dashed #ee4d2d;
          border-radius: 4px;
          text-align: center;
          line-height: 80px;
          cursor: pointer;
        }

        .el-icon-plus {
          color: #ee4d2d;
        }

        .goods-img {
          width: 80px;
          height: 80px;
          overflow: hidden;
          position: relative;
        }

        .goods-img_float {
          top: 50px;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: none;
          position: absolute;
          z-index: 1;
          text-align: center;
          line-height: 30px;
          cursor: pointer;

          .el-icon-delete {
            color: #fff;
          }
        }

        .goods-img:hover .goods-img_float {
          display: block;
        }
      }

      .goods-card {
        .el-upload--picture-card {
          font-size: 28px;
          color: #8c939d;
          width: 80px;
          height: 80px;
          line-height: 80px;
          text-align: center;
        }

        .el-upload-list__item-actions {
          width: 80px;
          height: 80px;
        }

        .el-upload-list--picture-card .el-upload-list__item {
          width: 80px;
          height: 80px;
        }
      }

      .el-icon-check {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
  }

  #disc-cell:hover {
    background: #fafafa;
  }
</style>